import React from "react";
import WhoWeAre from "../components/about/WhoWeAre";
import HeroSection from "../components/heroSection/HeroSection";
import ClientTestimonials from "../components/ClientTestimonials";
import AboutUs from "../components/AboutUs";


function About() {
  return (
    <div>
        <HeroSection page={"About"} />
      <WhoWeAre />
      <ClientTestimonials/>
      <AboutUs/>
    </div>
  );
}

export default About;