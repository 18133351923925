import React from "react";
import photo1 from "../../Assets/office-1.jpg";
import photo2 from "../../Assets/vision.jpg";
import photo3 from "../../Assets/mission.webp";
import Container from "../Container";
import MainHead from "../mainHead/MainHead";

function WhoWeAre() {
  return (
    <div className="body">
      <MainHead />
      <Container>
        <div className="text-white ">
          <div className="relative top-[-100px]">
            <div
              data-aos="flip-left"
              className=" flex md:flex-row flex-col bg-secondary "
            >
              <img
                src={photo1}
                alt="alghaith_office"
                className="bg-cover md:w-[50%] w-full"
              />

              <div className="flex  justify-center flex-col md:p-[50px] p-[30px]">
                <h2 className="sm:text-3xl text-2xl uppercase font-bold mb-[10px]">
                  Who We Are
                </h2>
                <p className="text-gray">
                  Al Ghaith Real Estate, Professionally, a developer.
                  Practically a community builder. Cultivating meaningful
                  connections and empowering growth for individuals and
                  businesses seeking vibrant and sustainable communities by
                  providing exceptional service, curating high-quality
                  properties, and fostering collaborative environments We take
                  responsibility for building our client growth with our Al
                  Ghaith community, solutions that caters for business processes
                  and improve efficiency. Contact us
                </p>
              </div>
            </div>

            <div
              data-aos="flip-right"
              className="flex md:flex-row flex-col-reverse   bg-secondary "
            >
              <div className="flex  justify-center flex-col md:p-[50px] p-[30px]">
                <h2 className="sm:text-3xl text-2xl uppercase font-bold mb-[10px]">
                  Our Vision
                </h2>
                <p className="text-gray">
                  To be the pioneer in building vibrant and sustainable
                  communities where individuals and businesses flourish through
                  exceptional living and working experiences.
                </p>
              </div>

              <img
                src={photo2}
                alt="vision"
                className="bg-cover md:w-[50%] w-full"
              />
            </div>
            <div
              data-aos="flip-left"
              className=" flex md:flex-row flex-col bg-secondary "
            >
              <img
                src={photo3}
                alt="alghaith_mission_statement"
                className="bg-cover md:w-[50%] w-full"
              />

              <div className="flex  justify-center flex-col md:p-[50px] p-[30px]">
                <h2 className="sm:text-3xl text-2xl uppercase font-bold mb-[10px]">
                  Our Mission
                </h2>
                <p className="text-gray">
                  To cultivate vibrant & sustainable communities by delivering
                  exceptional services, Curating high quality properties, and
                  fostering a collaborative environmemnt that empowers
                  individuals and businesses to thrive.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default WhoWeAre;
