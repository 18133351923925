import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="footer max-w-full mx-auto px-4 sm:px-6 bg-gray-100 border-t border-b py-30">
          {/* Top area: Blocks */}
          <div className="grid sm:grid-cols-12 gap-5 py-8 md:py-12 border-t border-gray-200 lg:ml-11">
            {/* 1st block */}
            <div className="col-span-12 lg:col-span-4">
              <img src="images/footer_logo.png" alt="Al ghaith properties logo" className=" " />

              {/* <div className=" border-blue-900 p-3 bg-gray-200 text-gray-600 text-center rounded-lg xl:w-80 mx-auto">
                <img
                  src="images/logo2.png"
                  alt="logo"
                  className="rounded-t float-right duration-1000 w-full h-15"
                />
                <h3 className="font-bold text-xl ">
                  Al Ghaith Properties
                </h3>
                <div className="text-md font-medium text-gray-600">
                  <h5>Office Suit 205,206,207</h5>
                  <p>Al Hana Centre</p>
                  <p> Al Mankhool Rd - Al Jaffiliya</p>
                  <p>Dubai, UAE</p>
                </div>
              </div> */}
            </div>

            {/* 2nd block */}
            <div className="col-span-6 md:col-span-6 lg:col-span-1 ml-7 mx-auto">
              <h6 className="text-white text-xl font-bold mb-4">LINKS</h6>
              <ul className="text-md">
                <li className="mb-2">
                  <HashLink
                    smooth to="/about#"
                    className="text-white hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                  >
                    About
                  </HashLink>
                </li>
                <li className="mb-2">
                  <HashLink
                    to="/#services"
                    className="text-white hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                  >
                    Services
                  </HashLink>
                </li>
                <li className="mb-2">
                  <HashLink
                    to="/contact#"
                    className="text-white hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                  >
                    Contact
                  </HashLink>
                </li>
              </ul>
            </div>

            {/* 3rd block */}
            <div className="col-span-6 md:col-span-6 lg:col-span-4 mx-auto">
              <h6 className="text-white text-xl font-bold mb-4">
                OUR SERVICES
              </h6>
              <ul className="text-md">
                <li className="mb-2">
                  <Link
                    to="#"
                    className="text-white hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                  >
                    Commercial Properties
                  </Link>
                </li>
                <li className="mb-2">
                  <Link
                    to="#"
                    className="text-white hover:text-white hover:tracking-wider transition duration-250 ease-in-out"
                  >
                    Residential Properties
                  </Link>
                </li>
                <li className="mb-2">
                  <Link
                    to="#"
                    className="text-white hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                  >
                    Maintenance
                  </Link>
                </li>
                {/* <li className="mb-2">
                            <Link to="#" className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">General IT Consultations</Link>
                        </li> */}
              </ul>
            </div>

            {/* 4th block */}
            <div className="col-span-12 text-center mx-auto lg:col-span-3 font-bold uppercase text-white">
              <div className="text-xl mb-6">Social Media Links.</div>

              <div className="text-md font-medium mb-6">
                Follow us on social media.
              </div>
              <div className="mx-auto text-center mt-2">
                <ul className="flex justify-center mb-4 md:mb-0">
                  <li>
                    <a
                      href="https://x.com/Alghaith_proper"
                      target="_blank"
                      rel="noreferrer"
                      className="rounded-full flex justify-center bg-white h-8 text-blue-900  w-8  mx-0 text-center pt-0"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 48 48"
                        className="w-5 h-5 mt-1.5 fill-current font-black hover:animate-pulse"
                      >
                        <path d="M4.002 43.998l14.509-16.789L3.002 6.998h13L26.615 20.83c.172.196.469.227.68.065.219-.168.26-.483.092-.701l-1.324-1.724 9.915-11.472h6.024L28.23 22.934l16.167 21.064H31.398L21.345 30.901 10.026 43.998H4.002zM33.121 43.002L7.021 8.994h9.256l-.767-1H5.021l26.868 35.008H33.121zM36.786 39.998l-22.254-29h-3.917l22.259 29H36.786zM24.01 46.998c11.035 0 19.992.225 19.992.501s-8.957.501-19.992.501S4.018 47.775 4.018 47.499 12.975 46.998 24.01 46.998z" />
                      </svg>
                    </a>
                  </li>
                  <li className="ml-4">
                    <a
                      href="https://www.linkedin.com/company/al-ghaith-properties/?viewAsMember=true"
                      target="_blank"
                      rel="noreferrer"
                      className="rounded-full flex justify-center bg-white h-8 text-blue-900  w-8  mx-0 text-center pt-1"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 50 50"
                        className="fill-current font-black hover:animate-pulse h-6 w-6"
                      >
                        {" "}
                        <path d="M41,4H9C6.24,4,4,6.24,4,9v32c0,2.76,2.24,5,5,5h32c2.76,0,5-2.24,5-5V9C46,6.24,43.76,4,41,4z M17,20v19h-6V20H17z M11,14.47c0-1.4,1.2-2.47,3-2.47s2.93,1.07,3,2.47c0,1.4-1.12,2.53-3,2.53C12.2,17,11,15.87,11,14.47z M39,39h-6c0,0,0-9.26,0-10 c0-2-1-4-3.5-4.04h-0.08C27,24.96,26,27.02,26,29c0,0.91,0,10,0,10h-6V20h6v2.56c0,0,1.93-2.56,5.81-2.56 c3.97,0,7.19,2.73,7.19,8.26V39z" />
                      </svg>
                    </a>
                  </li>
                  <li className="ml-4">
                    <a
                      href="https://www.facebook.com/profile.php?id=61564050883621"
                      target="_blank"
                      rel="noreferrer"
                      className="rounded-full flex justify-center bg-white h-8 text-blue-900  w-8  mx-1 text-center pt-1"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        className="fill-current font-black hover:animate-pulse"
                      >
                        <path d="M13.397 20.997v-8.196h2.765l.411-3.209h-3.176V7.548c0-.926.258-1.56 1.587-1.56h1.684V3.127A22.336 22.336 0 0 0 14.201 3c-2.444 0-4.122 1.492-4.122 4.231v2.355H7.332v3.209h2.753v8.202h3.312z"></path>
                      </svg>
                    </a>
                  </li>
                  <li className="ml-4">
                    <a
                      href="https://www.instagram.com/al_ghaith_properties/"
                      target="_blank"
                      rel="noreferrer"
                      className="rounded-full flex justify-center bg-white h-8 text-blue-900  w-8   text-center pt-1"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 48 48"
                        className="fill-current font-black hover:animate-pulse h- w-"
                       
                      >
                        <path d="M 16.5 5 C 10.159 5 5 10.159 5 16.5 L 5 31.5 C 5 37.841 10.159 43 16.5 43 L 31.5 43 C 37.841 43 43 37.841 43 31.5 L 43 16.5 C 43 10.159 37.841 5 31.5 5 L 16.5 5 z M 34 12 C 35.105 12 36 12.895 36 14 C 36 15.104 35.105 16 34 16 C 32.895 16 32 15.104 32 14 C 32 12.895 32.895 12 34 12 z M 24 14 C 29.514 14 34 18.486 34 24 C 34 29.514 29.514 34 24 34 C 18.486 34 14 29.514 14 24 C 14 18.486 18.486 14 24 14 z M 24 17 A 7 7 0 1 0 24 31 A 7 7 0 1 0 24 17 z" />
                      </svg>{" "}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="flex flex-wrap items-center md:justify-between justify-center mx-auto px-4">
            <div className="w-full md:w-3/12 px-4 mx-auto text-center py-2">
              <div className="text-sm text-gray-200 font-semibold py-1">
                Copyright &copy; {new Date().getFullYear()}
                {"  "}
                <HashLink to="#" className=" hover:text-gray-900">
                  Al Ghaith Properties
                </HashLink>
                . All rights reserved.
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer;
